.kontakt-wrapper {
    margin: auto;
    width: 100vw;
    max-width: 1800px;
    .map {
        width: 100%;
        height: 25rem;
        @media (max-width: 500px) {
            height: 20rem;
        }
        iframe {
            height: 25rem;
            width: 100%;
            @media (max-width: 500px) {
                height: 20rem;
            }
        }
    }
    .kontakt__text {
        font-size: clamp(0.9rem, 0.9vw, 1.3rem);
    }
    li {
        margin: 1rem 0;
        .line {
            transition: all 500ms;
            width: 0;
            height: 1px;
            background-color: $black;
        }
        &:hover {
            .line {
                width: 100% !important;
            }
        }
    }
}