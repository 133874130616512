.gdpr {
    max-width: 1800px;
    margin: auto;
    &-wrapper { 
        h1 {
            color: $black;
            font-size: clamp(2rem, 2.4vw, 3.5rem);
            border-bottom: 4px solid $light-red;
            width: fit-content !important;
            display: inline;
        }
        p, .text {
            color: $gray;
            font-size: clamp(1rem, .9vw, 1.4rem);
        }
        h3 {
            font-size: clamp(1.1rem, 1.4vw, 1.95rem);
            border-bottom: 3px solid $light-red;
            width: fit-content !important;
            color: $black;
            display: inline;
        }
        h6 {
            font-size: clamp(1rem, 1vw, 1.4rem);
            color: $black;
        }
    }
}