* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  list-style: none;
  scroll-behavior: smooth;
}
$main-red: #ed1d23;
$gray-font: #646464;
$light-red: #de4b41;
$gray-font: #646464;
$gray-nadpis: #303030;
//
$dark-grey: #565656;
$light-grey: #176767;
$white: #ffffff;

.alert-footer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: -25rem;
  z-index: 100;
}

.nejvetsi-container {
  @media (min-width: 2000px) {
    max-width: 1700px;
    margin: 0 auto;
  }
}

.transitions {
  transition: all 500ms ease-in-out;
}

.nadpis-underline-max {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 28px;
    width: 100%;
    left: 0;
    height: 4px;
    border-radius: 5px;
    background: $main-red;
    @media (max-width: 1280px) {
      bottom: 25px;
    }
  }
}
.nadpis-underline-half {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 30px;
    width: 50%;
    height: 4px;
    left: 50%;
    border-radius: 5px;
    background: $main-red;
    transform: translateX(-50%);
    @media (max-width: 1280px) {
      bottom: 28px;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: rgb(46, 46, 46); 
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 98, 98); 
}

::-webkit-resizer {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill {
  box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@import "./assets/styles/colors";
@import "./assets/styles/components/header";
@import "./assets/styles/components/forms";
@import "./assets/styles/components/footer";
@import "./assets/styles/pages/home";
@import "./assets/styles/pages/karta-detail";
@import "./assets/styles/pages/eshop";
@import "./assets/styles/components/jumbotron";
@import "./assets/styles/components/services";
@import "./assets/styles/pages/about";
@import "./assets/styles/pages/cookies";
@import "./assets/styles/pages/gdpr";
@import "./assets/styles/components/swiperComponent";
@import "./assets/styles/pages/kosik";
@import "./assets/styles/pages/kontakt";
@import "./assets/styles/pages/notFound";
@import "./assets/styles/components/kosikFirst";
@import "./assets/styles/components/kosikSecond";
@import "./assets/styles/components/kosikThird";
@import "./assets/styles/components/kosikFourth";
@import "./assets/styles/pages/administration";
@import "./assets/styles/components/administrationTable";
@import "./assets/styles/components/sideBar";
