.cart__container {
    max-width: 2500px;
    overflow-x: hidden;
    padding: calc(3rem + 2vh) calc(2rem + 4vw);
    @media screen and (max-width: 500px) {
        padding: calc(3rem + 2vh) calc(1rem + 1vw) !important;
    }
    button {
        font-size: clamp(1rem, .95vw, 2.1rem);
        @media screen and (min-width: 2000px) {
            width: 15rem;
        }
        &:hover {
            background-color: $dark-red;
            transition: 500ms ease-in-out;
        }
    }
    .cart__progressBar {
        span {
            font-size: clamp(1.1rem, 1.1vw, 2.25rem);
            transition: 500ms all;
        }
        .activeSpan {
            background-color: $red;
            color: white;
        }
        h3 {
            font-size: clamp(.9rem, 1vw, 2rem);
            @media screen and (min-width: 2000px) {
                bottom: -3rem;
            }
        }
        .width {
            max-width: 38rem;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill {
  box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}