.mySwiper {
  position: static !important;
  padding-bottom: 1.5rem !important;
  .swiper-pagination {
      width: fit-content !important;
      position: absolute !important;
      bottom: 1rem !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
    .swiper-pagination-bullet {
        opacity: 1 !important;
        background-color: #C4C4C4 !important;
        width: .8rem !important;
        height: .8rem !important;
        border-radius: 0 !important;
    }
    .swiper-pagination-bullet-active {
        opacity: 1 !important;
        background-color: #ED1D23 !important;
    }
    .swiperSlide-wrapper {
      width: 5rem;
      height: auto !important;
      transition: all 500ms !important;
      .swiperSlide-img {
        background-color: red;
        width: 100%;
        padding-top: 133%;
        position: relative;
        img{
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
        //height: 35rem;
        //background-position: center center;
        //background-repeat: no-repeat;
        //background-size: cover;
        //@media (max-width: 1080px) {
        //  height: 25rem;
        //}
        //@media (max-width: 768px) {
        //  height: 32.5rem;
        //  background-position: center 10%;
        //}
        //@media (max-width: 615px) {
        //  height: 25rem;
        //}
      }
      cursor: grab;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
      }
    }
}