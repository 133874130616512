.mainBox__third {
    max-width: 1800px;
    margin: auto;
    transition: all 500ms;
    @media (min-width: 2200px) {
        width: 100% !important;
    }
    .info {
        font-size: clamp(.9rem, .95vw, 1.35rem);
    }
    .shrnuti__item {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
        &:first-child {
            margin-top: 0 !important;
        }
        &:last-child {
            margin-bottom: 0 !important;
        }
        h1, .cena {
            font-size: clamp(1rem, 1vw, 1.5rem);
            line-height: 1;
        }
        p {
            font-size: clamp(.9rem, .95vw, 1.35rem);
        }
    }
    .celkovaCena {
        font-size: clamp(1.1rem, 1.1vw, 2rem);
    }
     button {
        font-size: clamp(.9rem, .95vw, 1.35rem);
    }
}