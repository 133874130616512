.nabidka-wrapper {
    .image-wrapper{
        background-color: red;
        width: 100%;
        padding-top: 133%;
        position: relative;
        img{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
    transition: all 500ms;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        border: 2px solid transparent;
    }
}