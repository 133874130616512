.mainBox__second {
    max-width: 1800px;
    margin: auto;
    transition: all 500ms;
    @media (min-width: 2200px) {
        width: 100% !important;
    }
        input {
            font-size: clamp(.9rem, .95vw, 1.35rem);
            &:focus {
                background-color: white;
            }
            &::placeholder {
                color: rgb(116, 108, 108);
                font-size: clamp(.9rem, .95vw, 1.35rem);
            }
        }
        .platba {
            label {
                font-weight: 500;
                margin-bottom: 0.375em;
                color: black;
                font-size: clamp(.9rem, .95vw, 1.35rem);
                input {
                    left: -9999px;
                    font-size: clamp(.9rem, .95vw, 1.35rem);
                    &:checked + span {
                        background-color: $red;
                        color: white;
                        border: 1px solid $red;
                        &:before {
                            box-shadow: inset 0 0 0 0.25em #1d2329;
                        }
                    }
                }
                span {
                    padding: 0.375em 0.75em 0.375em 0.375em;
                    border-radius: 99em;
                    transition: 0.25s ease;
                    font-size: clamp(.9rem, .95vw, 1.35rem);
                    border: 1px solid #4B5563;
                    &:hover {
                        background-color: $light-red;
                        border: 1px solid $light-red;
                    }
                    &:before {
                        display: flex;
                        flex-shrink: 0;
                        content: "";
                        background-color: rgb(236, 231, 231);
                        width: 1.5em;
                        height: 1.5em;
                        border-radius: 50%;
                        margin-right: 0.375em;
                        transition: 0.25s ease;
                        box-shadow: inset 0 0 0 0.1em $red;
                    }
                }
            }
        }
        button {
            font-size: clamp(.9rem, .95vw, 1.35rem);
        }
            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill {
            box-shadow: none;
            transition: background-color 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0 30px white inset !important;
            }
    }