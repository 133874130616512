.img-detail {
  height: 100%;
  max-height: 30rem;
  @media (max-width: 1536px) {
    max-height: 35rem;
  }
  @media (max-width: 768px) {
    max-height: 25rem;
  }
}

.seZabijuGrid{
  margin: auto;
  background-color: #e2e2e2;
  max-width: 1500px;
  @media (max-width: 768px) {
    max-width: unset;
    width: 95%;
  }

  .seZabijuImg{
    padding-right: 25px;
    width: 60% !important;
    display: block;
    float: left;
    height: 700px;
    overflow-y: hidden;
    margin-top: 50px;
    margin-bottom: 150px;
    img {
      width: 100%;
    }

    @media (max-width: 768px) {
      height: unset;
      width: 100% !important;
      margin-bottom: unset;
    }
  }
  .detail-text-container{
    margin-top: 50px;
    margin-bottom: 150px;
    width: 40%;
    float: right;
    display: block;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
