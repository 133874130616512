.sidebar {
  width: 18%;
  float: left;
  font-family: "Roboto Thin", sans-serif;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.25);
  height: fit-content;
  @media (max-width: 1580px) {
    height: 0rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    overflow: hidden !important;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      border: 1px solid #DFDFDF;
      #active {
        background-color: $red;
        color: white;
      }
      a {
        padding: 15px;
        display: block;
        font-weight: 600;
        color: black;
        text-decoration: unset;
        transition: 0.5s ease-out;
        &:hover{
          color: #fff;
          background-color: $red-hover;
        }
      }
    }
  }
}