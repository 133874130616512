.header-fixed {
  z-index: 1000;
}
.navbar-header-container {
  transition: all 500ms !important;
}
.header-li {
  margin: 1rem;
  padding: 0.3rem 0;
  font-weight: 500;
  letter-spacing: 2px;
  color: $white;
  @media (min-width: 1424px) {
    text-decoration: none;
    background: linear-gradient(red, red) bottom / 0 2px no-repeat;
    transition: 0.5s background-size;
    background-position: left bottom;
  }

  @extend .transitions;
  &:hover {
    color: $main-red;
    background-size: 100% 2px;
  }
}
/* Variations */

.uvod-li {
  font-weight: 500;
  letter-spacing: 2px;
}
.active-header {
  color: $main-red;
  @media (min-width: 1424px) {
    text-decoration: none;
    background: linear-gradient(red, red) bottom / 0 2px no-repeat;
    transition: 0.5s background-size;
    background-position: left bottom;
    background-size: 100% 2px;
  }
}

.header-logo {
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 2rem;
}
.ul-header-list {
  display: flex;
  @media (max-width: 1440px) {
    display: none;
  }
}

.navbar-button {
  @media (min-width: 1441px) {
    display: none;
  }
  font-size: 1rem;
  z-index: 102;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0.75rem;
  span {
    display: block;
    width: 25px;
    height: 2px;
    margin-bottom: 4px;
    position: relative;
    border-radius: 6px;
    z-index: 999999;
    transform-origin: 0 0;
    transition: all 0.5s ease-in-out;
    background: $white;
  }
  @media (max-width: 768px) {
    margin-right: 0.35rem;
  }
}

.navbar-button:hover span:nth-child(2) {
  transform: translateX(10px);
}

.navbar-button.isActive span:nth-child(1) {
  transform: translate(5px, -1.25px) rotate(45deg);
  background: $white;
}
.navbar-button.isActive span:nth-child(2) {
  transform: translateX(15px);
  opacity: 0;
}
.navbar-button.isActive span:nth-child(3) {
  transform: rotate(-45deg) translate(-1px, 5px);
  background: $white;
  font-weight: 600;
}
.menu-wrapper {
  z-index: 49;
}

//animace
#navbar-starter {
  top: 13.5rem;
}

.kosik {
  position: relative;
  &:after {
    content: attr(data-content);
    background: black;
    position: absolute;
    color: white;
    padding: 0.4rem 0.6rem;
    display: inline-block;
    font-weight: bold;
    font-size: clamp(0.6rem, 0.6vw, 1.4rem);
    width: fit-content;
    border-radius: 100%;
    right: -1rem;
    bottom: -.8rem;
  }
}

.darkMenu {
  background-color: #303030 !important;
  box-shadow: 0 4px 6px -2px rgb(136, 135, 135);
}

.activeKontakt {
  background-color: $main-red !important;
  color: white !important;
}
.languages{
  margin: 1rem;
  padding: 0.3rem 0;
  font-weight: 500;
  letter-spacing: 2px;
  color: $white;
  @media (min-width: 1424px) {
    text-decoration: none;
    background: linear-gradient(red, red) bottom / 0 2px no-repeat;
    transition: 0.5s background-size;
    background-position: left bottom;
  }
  height: fit-content;
  width: fit-content;
  li{
    width: fit-content;
    padding: 0;
    margin: 0 5px 0 5px;
  }
}