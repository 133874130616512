.eshop-buttons {
  flex-direction: row;
  @media (max-width: 325px) {
    flex-direction: column;
    button {
      &:nth-child(1) {
        margin-bottom: 0.5rem;
      }
    }
  }
}
