.mainBox__first {
    max-width: 1800px;
    margin: auto;
    transition: all 500ms;
    @media (min-width: 2200px) {
        width: 100% !important;
    }
    h1 {
        font-size: clamp(1.1rem, 1.1vw, 2rem);
    }
    p {
        font-size: clamp(.9rem, .95vw, 1.35rem);
    }
    .error {
        font-size: clamp(0.95rem, 0.9vw, 1.5rem);
    }
    .price {
        font-size: clamp(1.05rem, 1vw, 1.6rem);
    }
    .item {
        &:first-child {
            margin-top: -1rem !important;
        }
        &:last-child {
            border-bottom: none;
            margin-bottom: -1rem !important;
        }
    }
    .celkovaCena {
        font-size: clamp(1.05rem, 1vw, 2.1rem);
    }
    .prazdnyKosik {
        h1 {
            font-size: clamp(1.1rem, 1.4vw, 2.5rem);
        }
        button {
            width: fit-content !important;
            font-size: clamp(.9rem, .95vw, 1.35rem) !important;
        }
    }
    .span {
        span {
            &:first-child {
                top: 1.2rem;
            }
            &:last-child {
                top: 1rem;
            }
        }
    }
}