.form-wrapper {
  select{
    background: none;
    height: 42px;
  }
  input,
  textarea, select {
    border-radius: 8px;
    font-weight: 300;
    padding: 0.5rem 1rem;
    border: 1px solid #bbbbbb;
    font-weight: 400;
    &::placeholder {
      color: $light-grey;
      font-weight: 300;
    }
    @media (max-width: 650px) {
      font-size: 0.95rem;
    }
  }
  textarea {
    &::placeholder {
      padding-top: 0.2rem;
      color: $light-grey;
      font-weight: 300;
    }
  }
}

.global-form {
  width: 22.5rem;
  -webkit-box-shadow: 0px 5px 35px 10px rgba(153, 153, 153, 0.29);
  box-shadow: 0px 5px 35px 10px rgba(153, 153, 153, 0.29);
}
.registr-form {
  input, select {
    color: $dark-grey;
    border: 1px solid #565656;
    font-size: 1rem;
    &::placeholder {
      color: #6d6d6d;
    }
    @media (max-width: 650px) {
      font-size: 0.95rem;
    }
  }
}
input,
textarea,select {
  &:focus {
    background: rgb(243, 243, 243);
    outline: none;
  }
}
.about-formular-wrapper {
  -webkit-box-shadow: 0px 5px 35px 10px rgba(153, 153, 153, 0.29);
  box-shadow: 0px 5px 35px 10px rgba(153, 153, 153, 0.29);
  width: 45rem;
  padding: 0rem 2rem 1.5rem 2rem;
  @media (max-width: 768px) {
    padding: 0rem 1rem 1.5rem 1rem;
  }
}
