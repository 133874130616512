@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .velkej-nadpis {
    @apply 2xl:text-15xl xl:text-13xl lg:text-11xl md:text-10xl text-8xl;
  }
  .stredni-nadpis {
    @apply 2xl:text-11xl xl:text-10xl lg:text-8xl md:text-6xl sm:text-4xl text-3xl;
  }
  .malej-nadpis {
    @apply 2xl:text-8xl xl:text-7xl lg:text-6xl md:text-4xl text-3xl;
  }
  .mini-nadpis {
    @apply 2xl:text-4xl xl:text-3xl lg:text-2xl text-xl;
  }

  .stredni-text {
    @apply 2xl:text-2xl xl:text-xl lg:text-xl md:text-lg text-base;
  }
  .malej-text {
    @apply xl:text-xl lg:text-lg  sm:text-base;
  }

  .mini-text {
    @apply md:text-base  text-tiny leading-tight;
  }
  .extramini-text {
    @apply lg:text-base text-tiny leading-6;
  }

  /*   text */
  /*   text */

  .auto-padding {
    @apply 2xl:px-80 xl:px-64 lg:px-40 md:px-32 sm:px-20 px-5;
  }
  .auto-padding-big {
    @apply 2xl:px-64 xl:px-52 lg:px-44 md:px-32 sm:px-16 px-5;
  }
  .auto-padding-small {
    @apply 2xl:px-72 xl:px-64 lg:px-28 md:px-20 sm:px-14 px-5;
  }

  /*   alerts*/
  .alert-style {
    @apply p-4 rounded-lg md:text-lg text-base text-white font-medium;
  }
  /*   buttons */

  .btn-default {
    @apply my-6 md:py-2 py-1.5 w-52 hover:bg-main-red   leading-7 text-white font-semibold tracking-medium   rounded-lg justify-center items-center flex  md:text-xl text-lg bg-light-red;
  }

  .auto-margin-component {
    @apply md:mt-12 md:mb-24 mt-12 mb-16;
  }
  .under-line-container {
    @apply flex justify-center items-center;
  }
}
