.cookies {
    max-width: 1800px;
    margin: auto;
    &-wrapper { 
        h1 {
            color: $black;
            font-size: clamp(2rem, 2.4vw, 3.5rem);
            border-bottom: 4px solid $light-red;
            width: fit-content !important;
            display: inline;
        }
        p, .text {
            color: $gray;
            font-size: clamp(1rem, .9vw, 1.4rem);
        }
        h3 {
            font-size: clamp(1.1rem, 1.4vw, 1.95rem);
            border-bottom: 3px solid $light-red;
            width: fit-content !important;
            color: $black;
            display: inline;
        }
        h6 {
            font-size: clamp(1rem, 1vw, 1.4rem);
            color: $black;
        }
    }
    .blue {
        color: $light-red;
        font-weight: 500;
        transition: all 300ms;
        border-bottom: 2px solid transparent;
        &:hover {
            border-bottom: 2px solid $light-red;
        }
    }
    .tableDiv {
        overflow-x: auto;
    }
    table {
        text-align: left !important;
        overflow-x: auto;
        tr {
            border-bottom: 1px solid rgb(226, 224, 224);
            td {
                padding: 1rem 0;
                padding-right: 2rem;
                 &:nth-child(3) {
                    min-width: 20rem !important;
                }
            }
        }
    }
}