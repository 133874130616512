.about-img {
  height: 35rem;
}
.kdeByl{
  width: fit-content;
  div{
    max-width: 350px;
    @media(min-width: 1750px) {
      max-width: 300px;
    }
    margin: 0 2rem 0 2rem;
  }
}
.form-background{
  position: relative;
  height: fit-content;
img{
  z-index: -10;
  position: absolute;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center 10%;
  background-size: cover;
}

}