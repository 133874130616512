.error {
  font-size: 12px;
  color: red;
  padding-top: 5px;
}

.addedRowForm {
  height: fit-content !important;
}

.adminContainer .addedRowForm {
  width: 78%;
  min-height: 28rem;
  float: right;
  max-height: 800px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  @media (max-width: 1580px) {
    width: 95vw;
    flex-direction: column !important;
    margin-bottom: 5rem;
  }
  .adminSentBtn {
    background-color: #F9B233;
    color: white;
    border-radius: 8px;
    width: 100%;
    border: unset;
    padding: 10px 16px;
    transition: all 0.5s ease;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    cursor: pointer;

    &:hover {
      background: darken(#F9B233, 10%)
    }
  }

  .rowForm {
    padding: 5px;
    flex: 0 0 50%;
    input {
      width: 100%;
    }
  }

}

.infoBox {
  padding: 10px !important;

  div:nth-child(1) {
    padding: 10px;
    height: 100%;
    border-radius: 8px;
    background-color: #F6F3F1;
  }

  p {
    font-weight: 500;
    color: #959191;
    margin-bottom: 10px;
  }
}

.basicInput {
  width: 100%;
  padding: 10px 16px;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  letter-spacing: 1px;
  font-weight: 500;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #cf1d1d;
  }
}

.alert {
  display: block;
  margin-top: 8px;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-align: center;
  width: fit-content !important;
}
.errorAlert {
  background-color: #c83b3b;
  color: #ffffff;
  width: fit-content;
  padding: .8rem 2rem;
}
.succAlert {
  background-color: #41c454;
  color: #ffffff;
  width: fit-content;
  padding: .8rem 2rem;
}

.error {
  color:  #c83b3b;
  font-size: clamp(0.8rem, 0.8vw, 1rem);
}


.imagesVideo {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 25px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.imagesVideo > li {
  position: relative;
  padding-top: 66%;
}

.imagesVideo > li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

@media (max-width: 567px) {
  .imagesVideo {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}


.addCategory{
  display: flex;
  justify-content: space-between;
  @media (max-width: 650px) {
    display: block !important;
  }
  .addCategoryCol.addCategoryCol {
    flex: 0 0 50;
  }
}

.blogAdd, .vacanciesAdd {
  max-height: unset!important;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  @media (max-width: 1580px) {
    width: 95vw;
  }
  .blogRow, .vacanciesRow {
    flex: 0 0 100% !important;
  }
  .catRow {
    display: flex;
    flex-wrap: wrap;
  }
}

.catDis {
  position: relative;
  @media (max-width: 1250px) {
    width: 33.33%;
  }
  @media (max-width: 950px) {
    width: 50%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
  .borders {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: $main-red;
    transition: all 500ms;
    &:hover {
      background-color: $red;
    }
  }
  .spans {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    height: 1.5rem;
    span {
      height: 3px !important;
      &:first-child {
        transform: rotate(45deg);
      }
      &:last-child {
        transform: rotate(-45deg);
        top: -2.25px;
        position: relative;
      }
    }
  }
}

.editor {
  flex: 0 0 100% !important;
  @media (max-width: 800px) {
    display: block;
    width: 100%;
  }
}

.btn-add {
  background-color: $light-red;
  transition: all 500ms;
  font-size: 18px;
  &:hover {
    background-color: $red;
  }
}

.fileInput {
  color: #8c8c8c;
  overflow-x: hidden;
}

.addedRowFlex {
  margin-bottom: 5rem !important;
  @media (max-width: 1580px) {
    display: block !important;
  }
}

.fileInput {
  &:focus {
    background-color: white !important;
  }
}

.logOut__cotainer {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: fit-content !important;
    margin: auto;
    max-width: 1800px;
    margin: auto;
    display: flex;
    justify-items: center;
    align-items: center;
    color: $gray;
    h3 {
        font-size: clamp(1.2rem, 1.4vw, 2.6rem);
    }
    button {
        background-color: $red;
        transition: all 500ms;
        font-size: clamp(.9rem, .95vw, 1.5rem);
        &:hover {
          background: darken($red, 10%)
        }
    }
}

.description {
  width: 100%;
  padding: 10px 5px;
  font-size: 16px;
  background-color: #fff;
  border-radius: 8px;
  letter-spacing: 1px;
  font-weight: 500;
  transition: all 0.3s ease;
  color: $gray;
}