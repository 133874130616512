.mainBox__fourth {
    max-width: 1800px;
    margin: auto;
    transition: all 500ms;
    @media (min-width: 2200px) {
        width: 100% !important;
    }
    h1 {
        font-size: clamp(1.4rem, 1.6vw, 2.5rem);
    }
    p {
        font-size: clamp(.95rem, .95vw, 1.35rem);
    }
    button {
        width: fit-content !important;
        font-size: clamp(.9rem, .95vw, 1.35rem);
    }
}