.adminContainer {
  width: 80%;
  max-width: 1800px;
  @media (max-width: 1580px) {
    width: 95vw !important;
  }
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8rem;
  height: fit-content !important;
  z-index: 1;
  * {
    z-index: 1 !important; 
  }
  .addedControlls {
    min-height: 51px;
    width: 100%;
    display: flex;
    justify-content: right !important;
    float: right;
    align-items: center;
    margin-bottom: 1rem;
    @media (max-width: 1580px) {
      justify-content: space-between !important;
      float: none !important;
      width: 100%;
      flex-direction: row;
      order: 2 !important;
    }
      button, .adminHelperBtn {
        font-family: "Roboto Thin", sans-serif;
        background-color: $dark-red;
        color: white;
        padding: 10px 15px;
        border: unset;
        font-weight: 600;
        letter-spacing: 0.5px;
        cursor: pointer;
        font-size: 18px;
        transition: 0.3s;
        transition-timing-function: ease-in-out;
        text-decoration: unset;
        float: right;
        &:hover {
          background: darken($dark-red, 10%)
        }
      .download{
        background-color: #40a440 !important;
      }

      button:nth-child(1) {
        background-color: $red;
        color: white;
        &:hover {
          background: darken($red, 10%)
        }
      }
    }
  }


  .tableWrapper {
    width: 78%;
    float: right;
    margin-bottom: 4rem;
    max-height: 800px;
    min-height: 800px;
    overflow-y: scroll;
    overflow-x: scroll;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.25);
    white-space: nowrap; 
    @media (max-width: 1580px) {
      width: 95vw !important;
      overflow-x: auto !important;
      display: flex;
      flex-direction: column;
    }
  }
}

.sideBar-button {
      display: none;
      margin-left: .2rem;
      width: fit-content !important;
      @media (max-width: 1580px) {
          display: block !important;
      }
      span {
          display: block;
          width: 30px;
          height: 3px;
          margin-bottom: 4px;
          position: relative;
          z-index: 999999;
          transform-origin: 0 0;
          transition: all 500ms;
          background: rgb(22, 22, 22);
      }
      &:hover {
        span {
          &:nth-child(2) {
            transform: translateX(10px);
          }
        }
      }
    }


    .sideBar-button.sideBarIsActive span:nth-child(1) {
      transform: translate(5px, -1.25px) rotate(45deg);
    }
    .sideBar-button.sideBarIsActive span:nth-child(2) {
      transform: translateX(15px);
      opacity: 0;
    }
    .sideBar-button.sideBarIsActive span:nth-child(3) {
      transform: rotate(-45deg) translate(-1px, 5px);
      font-weight: 600;
    }
.adminTable {
  width: 100%;
  font-family: "Roboto Thin", sans-serif;
  border-collapse: collapse;
  @media (max-width: 1580px) {
    width: 1500px;
    overflow-x: auto !important;
  }

}

.adminTable thead {
  th {
    font-size: 18px;
    position: sticky;
    top: 0;
    letter-spacing: 1px;
    padding: 15px;
  }
}

.adminTable tbody {
  td {
    color: #575757;
    font-weight: 600;
    padding: 8px 15px 8px 15px;
  }
}


.adminTable .btn {
  padding: 10px 15px;
  border: unset;
  font-weight: 600;
  border-radius: 15px;
  letter-spacing: 0.5px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  transition-timing-function: ease-in-out;
  text-decoration: unset;
}

.adminTable .delete {
  background-color: #cd3a3a;
  color: white;
  &:hover {
    background: darken(#cd3a3a, 10%)
  }
}

.adminTable .unpack {
  background-color: #429fbd;
  color: white;

  &:hover {
    background: darken(#429fbd, 10%)
  }
}
.adminTable .action {
  background-color: #069604;
  color: white;
  &:disabled, &[disabled=disabled]{
    background: darken(#069604, 10%);
    cursor:default;
  }
  &:hover {
    background: darken(#069604, 10%)
  }
}
.adminTable .edit {
  background-color: #F9B233;
  color: white;

  &:hover {
    background: darken(#F9B233, 10%)
  }
}

.adminTable .basicRow:nth-child(even) {
  background-color: #f0f0f0;
}

.transitionRow {
  border-top: 1px solid #f0f0f0;
  background-color: white !important;
}

.adminTable tr:hover {
  background-color: #f5f5f5 !important;
}

.adminTable .additionalRow:nth-child(4n + 3) td {
  background-color: #f0f0f0;
}

.adminTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color:#303030;
  color: white;
}

.adminTable .hiddenRow {
  display: none;
}

.additional {
  color: #929292;
}

.btn-default {
  width: fit-content !important;
}