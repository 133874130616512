.footer-container {
  .nadpis-footer {
    color: $white;
    font-weight: 600;
  }
  .odkazy-footer {
    li {
      color: $dark-grey;

      &:hover {
        color: $white;
        @extend .transitions;
      }
    }
  }
  ul {
    padding-top: 0.35rem;
    line-height: 2rem;
    @media (max-width: 1280px) {
      line-height: 1.8rem;
    }
    @media (max-width: 1024px) {
      line-height: 1.45rem;
    }
    color: $white;
    .lajna-footer {
      top: 50%;
      transform: translateY(50%);
      height: 2px;
      @media (max-width: 1280px) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.form-wrapper {
  row-gap: 0.35rem;
}
//alerts

.alert-footer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: -25rem;
  z-index: 100;
}
.btn-footer {
  padding: 0.075rem 0;
}
//#google_translate_element div{
//  img{
//    display: none;
//  }
//}
