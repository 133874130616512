.homeKarta-img {
  height: 30rem;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center 10%;
  background-size: cover;
  border-bottom: 5px solid $main-red;
}
.landing-image {
  height: 100vh;
  max-height: 55rem;
  @media (max-width: 768px) {
    height: 30rem;
  }
}
.landing-image-container{
  position: relative;
  .main-image{
    @media(max-width: 1000px) {
      top: 5%;
    }
    @media(max-width: 768px) {
      top: 25%;
    }
    position: absolute;
    top: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
      text-align: center;
      color: white;
      font-size:70px;
      font-weight: 700;
      letter-spacing: 3px;
      @media(max-width: 800px) {
        font-size:50px;
      }
    }
    span{
      text-align: center;
      color: $red;
      font-size:40px;
      font-weight: 700;
      letter-spacing: 3px;
      @media(max-width: 800px) {
        font-size:20px;
      }
    }
    ul{
      margin: 0;
      color: white;
      span{
        @media(max-width: 800px) {
          font-size:15px;
        }
        color: white;
        font-size: 20px;
      }
    }
  }

}
