.jumbotron-container {
  .jumbotron-img {
    height: 35rem;
    @media (max-width: 1280px) {
      height: 27.5rem;
    }
    @media (max-width: 768px) {
      height: 20rem;
    }
  }
  .jumbotron-nadpis {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
  .jumbotron-podnadpis {
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: 5px;
    white-space: nowrap;

    @media (max-width: 768px) {
      top: 59%;
    }
  }
}
